export const experienceData = [
  {
    id: 1,
    company: "Big Barta Inc.",
    jobtitle: "Senior Software Architect",
    startYear: "November' 2017",
    endYear: "Present",
  },
  {
    id: 2,
    company: "Bit Mascot (Pvt.) Ltd.",
    jobtitle: "Senior Software Engineer (Technical Lead)",
    startYear: "September'2015",
    endYear: "October' 2017",
  },
  {
    id: 3,
    company: "Aprosoft Consulting and Training Corp Limited",
    jobtitle: "Senior Software Engineer",
    startYear: "January' 2013",
    endYear: "August' 2015",
  },
  {
    id: 4,
    company: "Mir Technologies Limited",
    jobtitle: "Development Team Lead",
    startYear: "March' 2012",
    endYear: "December' 2012",
  },
  {
    id: 5,
    company: "IT Magnet Pty Ltd",
    jobtitle: "Analyst Programmer",
    startYear: "April' 2011",
    endYear: "February' 2012",
  },
  {
    id: 6,
    company: "LEADS Corporation Limited",
    jobtitle: "Senior Software Engineer",
    startYear: "September' 2009",
    endYear: "March' 2011",
  },
  {
    id: 7,
    company: "CSL Software Resources Ltd",
    jobtitle: "Programmer",
    startYear: "July' 2007",
    endYear: "August' 2009",
  },
  {
    id: 8,
    company: "Microimage Bangladesh",
    jobtitle: "Programmer",
    startYear: "January' 2006",
    endYear: "June' 2007",
  },
];
