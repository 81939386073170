import "./Education.scss";
import EducationCard from "./EducationCard";
import { useThemeContext } from "../../contexts";
import { educationData } from "../../data/educationData";

const Education = () => {
  const [theme] = useThemeContext();

  return (
    <div
      className="education"
      id="resume"
      style={{ backgroundColor: theme.theme.palette.custom.secondary }}
    >
      <div className="education-body">
        <div className="education-description">
          <h1 style={{ color: theme.theme.palette.custom.primary }}>
            Education
          </h1>
          {educationData.map((edu) => (
            <EducationCard
              key={edu.id}
              id={edu.id}
              institution={edu.institution}
              course={edu.course}
              startYear={edu.startYear}
              endYear={edu.endYear}
            />
          ))}
        </div>
        <div className="education-image">
          <img src={theme.theme.palette.custom.eduimg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Education;
