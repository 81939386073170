export const educationData = [
    {
        id: 1,
        institution: 'Jahangirnagar University',
        course: 'Masters in Applied Statistics and Data Science',
        startYear: '2023',
        endYear: '2024'
    },
    {
        id: 2,
        institution: 'United International University',
        course: 'B.Sc. in Computer Science and Engineering',
        startYear: '2005',
        endYear: '2008'
    },
]