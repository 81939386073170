//import resume from '../assets/pdf/resume.pdf'
import avaterImage from "../assets/png/Sam - 1_BW.png";
import avaterImage1 from "../assets/png/Sam - 1.png";


export const headerData = {
  name: "Sayem",
  title: "Senior Software Architect",
  desciption:
    "As a Senior Software Architect I am a leader in the field of software development, I designs and oversees the implementation of complex software systems. I have extensive knowledge of various programming languages, software architectures, design patterns and best practices.",
  image: avaterImage1,
  resumePdf: "https://avatars.githubusercontent.com/u/15273731",
};
