import { Fade } from "react-awesome-reveal";
import { makeStyles } from "@material-ui/core/styles";

import eduImgWhite from "../../assets/svg/education/eduImgWhite.svg";
import eduImgBlack from "../../assets/svg/education/eduImgBlack.svg";
import "./Education.scss";
import { useThemeContext } from "../../contexts";
interface Props {
  id: number;
  institution: string;
  course: string;
  startYear: string;
  endYear: string;
}
const EducationCard = ({
  id,
  institution,
  course,
  startYear,
  endYear,
}: Props) => {
  const [theme] = useThemeContext();

  const useStyles = makeStyles((t) => ({
    educationCard: {
      backgroundColor: theme.theme.palette.custom.primary30,
      "&:hover": {
        backgroundColor: theme.theme.palette.custom.primary50,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Fade direction="up" style={{ width: "100%" }}>
      <div key={id} className={`education-card ${classes.educationCard}`}>
        <div
          className="educard-img"
          style={{ backgroundColor: theme.theme.palette.custom.primary }}
        >
          <img
            src={
              theme.theme.palette.custom.type === "light"
                ? eduImgBlack
                : eduImgWhite
            }
            alt=""
          />
        </div>
        <div className="education-details">
          {/* <h6 style={{ color: theme.theme.palette.custom.primary }}>
            {startYear}-{endYear}
          </h6> */}
          <h4 style={{ color: theme.theme.palette.custom.tertiary }}>
            {course}
          </h4>
          <h5 style={{ color: theme.theme.palette.custom.tertiary80 }}>
            {institution}
          </h5>
        </div>
      </div>
    </Fade>
  );
};

export default EducationCard;
